import React from "react"
import PageImageHeader from "../components/common/PageImageHeader"
import SectionTitle from "../components/common/SectionTitle"
import Layout from "../components/layout"
import HeaderImage from "../images/contactImage.jpeg"
import styled from "styled-components"

const StyledP = styled.p`
  text-align: center;
  margin: 0 auto;
  padding: 2rem;
`

const StyledForm = styled.div`
  max-width: 800px;
  margin: 0 auto;
  input {
    width: 95%;
    margin: 0.5rem 0.5rem;
    padding: 0.5rem 0;
    border-radius: 8px;
    border: 1px solid black;
    font-size: 1em;
  }
  textarea {
    width: 95%;
    height: 150px;
    margin: 0.5rem 0.5rem;
    padding: 0.5rem 0;
    border-radius: 8px;
    border: 1px solid black;
    font-size: 1.3em;
  }
  button {
    background-color: #be1e2d;
    border: none;
    color: white;
    padding: 0.5rem;
    font-size: 1em;
    margin: 1rem;
  }
`

const ContactPage = () => {
  return (
    <Layout>
      <PageImageHeader imageHeader={HeaderImage} />
      <SectionTitle title="Contact Us" />
      <StyledP>
        We would love to hear from you. Want to connect? Let us know what is on
        your mind.
      </StyledP>
      <StyledForm>
        <form
          method="POST"
          name="contact"
          data-netlify="true"
          action="/contact-success"
        >
          <input type="hidden" name="form-name" value="contact" />
          <input type="text" name="name" placeholder="name *" required />
          <input type="email" name="email" placeholder="email *" required />
          <textarea
            name="whats-up"
            placeholder="what's on your mind?"
            required
          />{" "}
          <button type="submit">Submit</button>
        </form>
      </StyledForm>
    </Layout>
  )
}

export default ContactPage
